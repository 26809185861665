<template>
  <div class="homepage">
    <div class="rf-sign-up">
      <b-container>
        <b-row class="mt-5">

          <b-col cols="12" md="6" class="position-relative mt-5 complete-registration" v-if="fromReservation">
            <b-row>
              <b-col cols="12" md="10">
                <h3 class="title">{{$t('Complete registration')}}</h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <div class="email mt-3">{{email}}</div>
                <RFForm :fields="fieldsPassword" :loading="loading" @submit="completeRegistration"/>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" md="6" class="position-relative mt-5 complete-registration" v-if="!fromReservation">
            <h3>{{$t('Your account has been successfully created!')}}</h3>
            <h6>{{$t('Now, you can login using your email and password.')}}</h6>
            <router-link to="/auth/login">
              <RFButton class="px-5" :label="$t('Login')" background="#24425B" color="#fff" />
            </router-link>
          </b-col>

        </b-row>
      </b-container>
    </div>
    <div class="rf-type">
      <b-container fluid>
        <b-row>
          <b-col cols="12" md="6" class="rf-sign-up apartments mb-5 mb-md-0 px-0">
            <div class="rf-apartments">
              <h1 class="title">{{$t('Apartments')}}</h1>
              <router-link :to="{ name: 'apartments-page' }">
                <RFButton class="px-5" :label="$t('Discover')" background="#67AEA6" color="#fff" border-color="transparent"/>
              </router-link>
            </div>
          </b-col>
          <b-col  cols="12" md="6" class="rf-sign-up buildings px-0">
            <div class="rf-buildings">
              <h1 class="title">{{$t('RooF Buildings')}}</h1>
              <router-link to="/buildings">
                <RFButton class="px-5" :label="$t('Discover')" background="#E26F5A" color="#fff" border-color="transparent"/>
              </router-link>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="rf-icons mt-5">
     <img class="w-100" src="@/assets/images/home_rf_icons.jpg">
    </div>
  </div>
</template>

<script>
import RFForm from '@/components/forms/RFForm'
import completeRegistrationFields from '@/json/forms/complete-registration.json'
import { extractValueByField, replaceFieldValue } from '@/helpers'
import { userService } from '@/services'
import loginFields from '@/json/forms/login.json'
import RFButton from '@/components/forms/RFButton'
import i18n from "@/plugins/i18n";
import { isAdmin } from '@/helpers/functions'
import { authService } from '@/services'

export default {
  components: { RFForm, RFButton },
  name: 'CompleteRegistration',
  data() {
    return {
      isValidToken: false,
      id: null,
      email: null,
      fieldsPassword: completeRegistrationFields,
      fieldsLogin: loginFields,
      password: null,
      confirmPassword: null,
      error: null,
      loading: false,
      fromReservation: true,
      form: {
        email: null,
        password: null,
      },
      user: {}
    }
  },
  async beforeRouteEnter(to, from, next) {
    if (!to.query.email || !to.query.token || !to.query.id) {
      next({ name: 'homepage' })
      return
    }

    try {
      const { data: isValidToken } = await userService.validateTokenComplete(to.query.email, to.query.token)

      if (!isValidToken) {
        next({ name: 'homepage' })
      } else {
        next()
      }
    } catch (error) {
      console.log(error)
    }
  },
  goToLogin() {
     this.$router.push({ name: 'login' })
  },
  async beforeMount() {
    this.id = this.$route.query.id
    this.email = this.$route.query.email
    const result = await userService.getUserById(this.$route.query.id)
    if (result.status === 200) {
      this.user = result.data
    } else {
      this.$router.push({ name: 'homepage' })
    }
    if (this.user.password === null || this.user.password === undefined) {
      this.fromReservation = true
    } else {
      this.fromReservation = false
    }
  },
  methods: {
    async login() {
      this.loading = true

      this.form.email = i18n.t(extractValueByField(this.fieldsLogin, 'email'))
      this.form.password = i18n.t(extractValueByField(this.fieldsLogin, 'password'))

      const loggedUser = await authService.login(this.form)

      this.loading = false

      if (this.$store.state.userStore.loggedIn) {
        replaceFieldValue(this.fieldsLogin, 'email', null)
        replaceFieldValue(this.fieldsLogin, 'password', null)

        if (isAdmin(loggedUser.roles)) {
          this.$router.push({ name: 'admin' })
        } else {
          this.$router.push({ name: 'profile' })
        }
      }
    },
    async completeRegistration() {
      this.password = extractValueByField(this.fieldsPassword, 'password')
      this.confirmPassword = extractValueByField(this.fieldsPassword, 'confirm-password')

      if (this.password !== this.confirmPassword) {
        this.$toasted.error(this.$i18n.t(`Password and confirm password doesn't match`))
        return
      }

      try {
        this.loading = true

        const auth = {
          newPassword: this.password,
          token: this.$route.query.token,
        }

        const { status } = await userService.addPassword(this.id, auth)

        if (status !==  200) {
          this.$toasted.error(this.$i18n.t('Error while completing registration'))
          return false
        }

        this.loading = false
        this.$toasted.success(this.$i18n.t('Registration completed!'))
        this.$router.push({ name: 'login' })
      } catch (error) {
        console.log(error)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.login {
    width: 250px;
    color: #24425B !important;

    .menu-item {
      padding: 10px 20px;
      width: 100%;
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
.complete-registration {

  .title {
    @include font-style($montserrat, 'semibold', $font-30);
    color: $dark-blue;
  }
  .email {
    @include font-style($montserrat, 'medium', $font-14);
  }
}
.login-modal {
  background-color: $white;
  padding: 10px 20px 0px 20px;
  width: 300px;

  .forgot-password {
    @include font-style($montserrat, 'semibold', $font-14);
    color: $dark-blue;
  }
}
.homepage {
  .rf-sign-up {
    margin-top: 10vh;
    position: relative;

    .accomodation-title {
      @include font-style($montserrat-bold, 'bold', $font-26);
      color: $dark-blue;
    }
    .accomodation-text {
      margin-top: 40px;

      .text-line {
        @include font-style($montserrat, 'medium', $font-16);
        color: $dark-blue;
        margin: 0;
      }
    }
    .accommodation-texture {
      width: 100vw;
      background-image: url('/images/gray_texture.png');
      position: absolute;
      height: 200px;
      z-index: 5;

      &.first {
        left: -100vw;
      }
    }
    .booking-form-container {
      background-color: $dark-blue;
      padding: 15px;
      position: relative;
      z-index: 5;

      &:after,
      &:before {
        content: '';
        width: 100vw;
        height: 100%;
        background-color: $dark-blue;
        position: absolute;
        top: 0;
        z-index: -1;
      }
      &:before {
        right: 50%;
      }
      &:after {
        left: 50%;
      }

      .booking-label {
        @include font-style($montserrat-bold, 'bold', $font-14);
        color: $white;
        margin-top: 20px;
        white-space: nowrap;
      }
    }
  }
  .rf-explore {
    background: $gray;
    padding: 30px 0;

    .explore-text {
      @include font-style($montserrat-bold, 'bold', $font-20);
      text-align: center;
      margin: 0;
      color: $dark-blue;
    }
  }
  .rf-type {
    position: relative;

    .rf-sign-up {
      height: 50vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.apartments {
        background-image: url('/images/A-image.jpg');
        border-bottom: 10px solid $green;

        .rf-apartments {
          text-align: center;
        }
      }
      &.buildings {
        background-image: url('/images/R-image.jpg');
        border-bottom: 10px solid $orange;

        .rf-buildings {
          text-align: center;
        }
      }
    }
    .title {
      @include font-style($montserrat-bold, 'bold', $font-24);
      color: $white;
    }
  }
  .rf-services {
    padding: 100px 0;

    .title {
      @include font-style($montserrat-bold, 'bold', $font-26);
      color: $dark-blue;
      margin-bottom: 50px;
    }
    .text {
      @include font-style($montserrat, 'medium', $font-16);
      color: $dark-blue;
    }
    .service-image {
      width: 60%;
    }
  }
  .rf-roof-world {
    background-image: url('/images/home_roof_world.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 70vh;
    display: flex;
    align-items: center;

    .title {
      @include font-style($montserrat-bold, 'bold', $font-26);
      color: $dark-blue;
    }
    .text {
      @include font-style($montserrat, 'medium', $font-16);
      color: $dark-blue;
      margin-top: 100px;
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .homepage {
    .rf-sign-up {
      .accomodation-text {
        width: 80%;
      }
      .booking-form-container {
        padding: 50px 0 50px 50px;
        position: static;
        z-index: 0;

        &:before {
          display: none;
        }
        &:after {
          display: block;
        }
        .booking-form {
          position: relative;
          z-index: 10;
        }
      }
    }
    .rf-type {
      .rf-sign-up {
        height: 60vh;

        &.buildings,
        &.apartments {
          // align-items: flex-end;

          .rf-buildings,
          .rf-apartments {
            padding: 0px;
            text-align: center;
          }
        }
      }
    }
    .rf-services {
      .service-image {
        width: 40%;
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .homepage {
    .rf-services {
      .service-image {
        width: 80px;
      }
    }
  }
}
</style>
